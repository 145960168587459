<template>
  <AppLayout>
    <div
      style="
        padding-top: 128px;
        padding-bottom: 100px;
        background-color: #f2f2f2;
      "
    >
      <div class="container">
        <div class="back-button" @click="clickBack">
          <img src="./image/back.png" alt="" class="img-back" />
          <img src="./image/back1.png" alt="" class="img-back-hover" />
          <span>返回</span>
        </div>
        <div class="title">优惠券详情</div>
        <div class="container-bottom">
          <div class="coupon-detail">
            <img
              src="./image/received.png"
              alt=""
              v-if="params.state == 2"
            />
	          <img
			          src="./image/use.png"
			          alt=""
			          v-if="params.state == 3"
	          />
            <div class="box-left">
              <div>
                <span>￥</span>
                <span>{{ detailData.couponAmount }}</span>
              </div>
              <div>满{{ detailData.amountOver }}元可用</div>
            </div>
            <div class="box-center">
              <span>{{ detailData.couponName }}</span>
              <span>{{ detailData.type == 0 ? "代金券" : "" }}</span>
              <span
                >{{ detailData.validityStart }} 至
                {{ detailData.validityEnd }}</span
              >
            </div>
          </div>
          <button class="box-right-button" @click="clickReceive" v-if="params.state == 1">
            立即领取
          </button>
	        <button class="box-right-button" @click="$router.push('/commercialProduct')"  v-if="params.state == 2">
		        立即使用
	        </button>
          <div class="explain" v-if="detailData.couponCode">
            <p>优惠券券码：{{ detailData.couponCode }}</p>
          </div>
          <div class="explain">
            <p>优惠券说明：</p>
            <p>
              {{detailData.description}}
            </p>
          </div>
        </div>
      </div>
    </div>
  </AppLayout>
</template>

<script setup>
import AppLayout from "@/components/AppLayout.vue";
import { onMounted, ref } from "vue";
import { useRoute } from "vue-router";
import {couponDetail, couponReceive, getCouponReceive} from "@/api/coupon";
import { ElMessage } from "element-plus";
const route = useRoute();

const params = ref(route.params);
const detailData = ref({}); // 优惠券详情数据
onMounted(() => {
	if(params.value.receiveId == 1){
		// 未领取优惠券
		couponDetail(params.value.id).then((res) => {
			detailData.value = res.data;
		});
	}else{
		// 已领取优惠券
		getCouponReceive(params.value.receiveId).then((res) => {
			detailData.value = res.data;
		});
	}
});

const clickBack = () => {
  window.history.go(-1);
};

const clickReceive = () => {
  couponReceive(detailData.value.couponNumber).then((res) => {
    if (res.code == 200) {
      ElMessage({
        message: "领取成功！",
        type: "success",
      });
    } else {
      ElMessage({
        message: res.message,
        type: "error",
      });
    }
  });
};
</script>

<style scoped lang="less">
.container {
  padding-top: 49px;
  width: 1080px;
  height: 700px;
  background: #ffffff;
  .back-button {
    cursor: pointer;
    margin-left: 63px;
    .img-back {
      width: 8px;
      height: 14px;
      position: relative;
      opacity: 1;
    }
    .img-back-hover {
      width: 8px;
      height: 14px;
      position: absolute;
      opacity: 0;
    }
    span {
      font-family: Microsoft YaHei;
      font-weight: 400;
      font-size: 14px;
      color: #666666;
    }
    &:hover .img-back {
      position: absolute;
      opacity: 0;
    }
    &:hover .img-back-hover {
      position: relative;
      opacity: 1;
    }
    &:hover span {
      color: #ce1200;
    }
  }
  .title {
    text-align: center;
    font-family: Microsoft YaHei;
    font-weight: 400;
    font-size: 18px;
    color: #111111;
  }
  .container-bottom {
    width: 900px;
    margin: 0 auto;
    .coupon-detail {
      display: flex;
      margin-top: 46px;
      margin-bottom: 12px;
      width: 900px;
      height: 160px;
      background-image: url("./image/background.png");
      background-size: cover;
      img {
        position: absolute;
        margin-left: 16px;
        margin-top: -8px;
        width: 51px;
        height: 52px;
      }
      .box-left {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 180px;
        div:nth-child(1) {
          span:nth-child(1) {
            font-family: Microsoft YaHei;
            font-weight: 400;
            font-size: 16px;
            color: #ce1200;
          }
          span:nth-child(2) {
            font-family: Microsoft YaHei;
            font-weight: bold;
            font-size: 28px;
            color: #ce1200;
          }
        }
        div:nth-child(2) {
          font-family: Microsoft YaHei;
          font-weight: 400;
          font-size: 14px;
          color: #ce1200;
        }
      }
      .box-center {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-left: 47px;
        width: 527px;
        span:nth-child(1) {
          font-family: Microsoft YaHei;
          font-weight: 400;
          font-size: 17px;
          color: #111111;
        }
        span:nth-child(2) {
          margin: 5px 0;
          font-family: Microsoft YaHei;
          font-weight: 400;
          font-size: 14px;
          color: #666666;
        }
        span:nth-child(3) {
          font-family: Microsoft YaHei;
          font-weight: 400;
          font-size: 14px;
          color: #999999;
        }
      }
      .box-right {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 240px;
      }
    }
    .box-right-button {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 auto;
      width: 110px;
      height: 40px;
      background: #ce1200;
      border: none;
      border-radius: 5px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      font-size: 15px;
      color: #ffffff;
      cursor: pointer;
    }
    .explain {
      margin-top: 20px;
      p:nth-child(1) {
        font-family: Microsoft YaHei;
        font-weight: 400;
        font-size: 15px;
        color: #333333;
      }
      p:nth-child(2) {
        font-family: Microsoft YaHei;
        font-weight: 400;
        font-size: 13px;
        color: #666666;
      }
    }
  }
}
</style>
